import { browserLogger as logger } from '@/shared/instrumentation/browserLogger';
import { endOfTomorrow, isAfter, isToday, isTomorrow } from 'date-fns';
import { compact } from 'lodash';
import {
	AppointmentResourceTypeEnum,
	type Address,
	type Appointment,
	type AppointmentCore,
	type AppointmentCoreExtensionInner,
	type AppointmentsAppointmentIdGetLocaleEnum,
	type Bundle,
	type Coding,
	type Extension,
	type HealthcareService,
	type HumanName,
	type Location,
	type Patient,
} from 'services/midtier/booking-management/appointment-history/__generated';
import type { PCHAppointmentStatus } from '../appointmentBooking/widgets';
import { MTFHIRDataMappingContract, type ExtensionPair } from './contract';

// Confirmed with MT - this remains same in all envs
const EXTENSION_PREFIX = 'https://api.loblaw.ca/pch/cah/ext/';

export type AppointmentDetails = {
	id: string;
	serviceName?: string;
	subtype?: string;
	mapDetails?: {
		latitude?: number;
		longitude?: number;
	};
	locationDetails?: {
		title?: string;
		address?: Address;
		phone?: string;
	};
	patientList?: HumanName[];
	date?: Date;
	status?: PCHAppointmentStatus;
	appointmentMethod?: string;
	countOfOtherPatient: number;
};

export type LoadAppointmentProps = {
	localeCode: AppointmentsAppointmentIdGetLocaleEnum;
};

export type LoadAppointmentByIdProps = LoadAppointmentProps & {
	appointmentId: string;
};

type BundleOrError = {
	error?: Error;
	data?: Bundle;
};

const handleError = (msg: string) => {
	logger.error(msg);
};

const makeGetRequest = async (url: string) => {
	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'content-type': 'application/json',
			},
		});
		if (response.ok) {
			return response.json();
		} else {
			throw new Error(`Failed to load data: ${response.status} ${response.statusText}`);
		}
	} catch (error) {
		handleError('Error attempting to make GET request. ' + error);
	}
};

export const loadAppointmentsHandler = async ({ localeCode }: LoadAppointmentProps) => {
	return makeGetRequest(`/api/appointment-history/getAppointments/?localeCode=${localeCode}`);
};

export const loadAppointmentByIdHandler = async ({ localeCode, appointmentId }: LoadAppointmentByIdProps) => {
	if (!appointmentId?.trim()) {
		throw new Error('Appointment ID must not be empty');
	}

	return makeGetRequest(`/api/appointment-history/getAppointments/${appointmentId}?localeCode=${localeCode}`);
};

export const unlinkAppointmentHandler = async (appointmentId: string): Promise<BundleOrError> => {
	if (!appointmentId?.trim()) {
		throw new Error('Appointment ID must not be empty');
	}

	const url = `/api/booking/delink?id=${appointmentId}`;
	const response = await fetch(url, {
		method: 'PATCH',
		headers: {
			'content-type': 'application/json',
		},
	});
	if (response.status === 200) {
		return response.json();
	} else {
		throw new Error(`Failed to make request: ${response.status} ${await response.text()}`);
	}
};

export type PCHAppointment = {
	id?: string;
	status?: PCHAppointmentStatus;
	year?: number;
	start?: Date;
	end?: Date;
	type?: string;
	patientFirstName?: string;
	patientLastName?: string;
	title?: string;
	subtitle?: string;
	appointmentMethod?: string;
	locationName?: string;
};

export const extractAppointmentsFromFHIRBundle = (bundle: Bundle): (Appointment | AppointmentCore)[] => {
	if (!bundle || !bundle.entry) return [];
	try {
		const fhirAppointments = compact(
			bundle.entry.map((e) =>
				e.resource?.resourceType === AppointmentResourceTypeEnum.Appointment ? <Appointment>e.resource : undefined
			)
		);
		return fhirAppointments;
	} catch (error) {
		handleError('Error attempting to make extract appointments from bundle. ' + error);
		return [];
	}
};

export const transformFHIRAppointmentsToPCHAppointments = (
	fhirAppointments: (Appointment | AppointmentCore)[]
): PCHAppointment[] => {
	const failedAppointmentIds: string[] = [];
	const PCHAppointments = compact(
		fhirAppointments.map((fhirAppointment) => {
			try {
				const extensions = fhirAppointment.extension;

				const getApptMethod = () => {
					const apptMethodObj = getPropertyFromExtension<Record<string, string>>(
						extensions,
						MTFHIRDataMappingContract.appointmentMethod
					);
					return apptMethodObj?.code;
				};

				return {
					id: fhirAppointment.id,
					status: calculatePCHAppointmentStatus(fhirAppointment.start, fhirAppointment.status),
					year: fhirAppointment.start ? new Date(fhirAppointment.start).getFullYear() : undefined,
					start: fhirAppointment.start ? new Date(fhirAppointment.start) : undefined,
					end: fhirAppointment.end ? new Date(fhirAppointment.end) : undefined,
					title: getPropertyFromExtension<string>(extensions, MTFHIRDataMappingContract.healthcareServiceName),
					subtitle: getPropertyFromExtension<string>(extensions, MTFHIRDataMappingContract.subtype),
					type: getPropertyFromExtension<string>(extensions, MTFHIRDataMappingContract.healthcareServiceName),
					patientFirstName: getPropertyFromExtension<string>(extensions, MTFHIRDataMappingContract.patientFirstName),
					patientLastName: getPropertyFromExtension<string>(extensions, MTFHIRDataMappingContract.patientLastName),
					locationName: getPropertyFromExtension<string>(extensions, MTFHIRDataMappingContract.pharmacyName),
					appointmentMethod: getApptMethod(),
				};
			} catch (error) {
				failedAppointmentIds.push(fhirAppointment.id || 'fhirAppointment id not found');
			}
		})
	);
	if (failedAppointmentIds.length > 0) {
		const errMessage =
			'Error attempting to transform FHIR appointments to PCH appointment, failed appointment IDs = ' +
			failedAppointmentIds.reduce((prev, curr) => prev + ', ' + curr, '');
		handleError(errMessage);
	}
	return PCHAppointments;
};

const getExtensionUrl = (name: string) => EXTENSION_PREFIX + name;

const getPropertyFromExtension = <T>(
	extensions: Extension[] | AppointmentCoreExtensionInner[] | undefined,
	extensionPair: ExtensionPair
): T | undefined => {
	if (!extensions) return undefined;
	const { key, type } = extensionPair;
	const extension = extensions.find((extension) => extension.url && extension.url === getExtensionUrl(key));
	if (extension && Object.hasOwnProperty.call(extension, type)) {
		const result = extension[type];
		return result as T;
	}
	return undefined;
};

/**
 * Calculate PCH appointment status based on Midtier appointment FHIR status and date
 * MidTier appointment has FHIR statuses: proposed | pending | booked | arrived | fulfilled | cancelled | noshow | entered-in-error | checked-in | waitlist
 */
export const calculatePCHAppointmentStatus = (
	start: string | number | Date | undefined,
	status: string | undefined
): PCHAppointmentStatus | undefined => {
	if (!status || !start) {
		throw Error('Missing field to calculate appointment status');
	}
	const startDate = new Date(start);
	if (status === 'booked' && isToday(startDate)) {
		return 'today';
	}
	if (status === 'booked' && isTomorrow(startDate)) {
		return 'tomorrow';
	}
	if (status === 'checked-in' || (status === 'booked' && isAfter(startDate, endOfTomorrow()))) {
		return 'upcoming';
	}
	if (status === 'fulfilled') {
		return 'completed';
	}
	if (status === 'cancelled' || status === 'noshow') {
		return 'cancelled';
	}
	/** Ignore the other statuses: entered-in-error | proposed | pending | waitlist | arrived
	 * Still show the appointment but no StatusTag */
	return undefined;
};

/**
 * Extracts appointment details from a FHIR bundle.
 *
 * @param {Bundle} bundle - The FHIR bundle from which to extract details.
 * @returns {object} An  AppointmentDetails object containing the extracted details, including address, patientList, healthcareServiceName, position, storeName, date, and storeContact.
 */
export const extractAppointmentDetailsFromBundle = (bundle: Bundle): AppointmentDetails[] => {
	if (!bundle || !bundle.entry)
		throw new Error('Invalid or empty FHIR bundle provided for extractAppointmentDetailsFromBundle');
	let serviceName, patientList, mapDetails, date, locationDetails, status, appointmentMethod;
	let countOfOtherPatient = 0;
	try {
		for (const item of bundle.entry) {
			const resource = item.resource!;
			let title, address, phone;

			if (!resource) continue;

			switch (resource.resourceType) {
				case 'Appointment': {
					const appointment = <Appointment>resource;
					if (appointment.start) {
						date = new Date(appointment.start);
						status = calculatePCHAppointmentStatus(date, appointment.status);
					}

					if (appointment.extension) {
						const appointmentMethodExtension = appointment.extension.find(
							(ext) => ext.url?.includes('appointment-method')
						);
						if (appointmentMethodExtension && typeof appointmentMethodExtension.valueCoding === 'object') {
							const coding: Coding = appointmentMethodExtension.valueCoding;
							if (coding.code) {
								appointmentMethod = coding.code;
							}
						}

						const appointmentCountOfOtherPatient = appointment.extension.find(
							(ext) => ext.url?.includes('number-of-patients')
						);
						// subtract 1 from the count as the person who booked is included
						countOfOtherPatient =
							appointmentCountOfOtherPatient &&
							'valueInteger' in appointmentCountOfOtherPatient &&
							appointmentCountOfOtherPatient.valueInteger !== undefined
								? appointmentCountOfOtherPatient.valueInteger - 1
								: 0;
					}

					break;
				}
				case 'Location':
					const location = <Location>resource;
					title = location.name;
					if (location.contact && location.contact[0].telecom) {
						phone = location.contact[0].telecom[0].value;
					}
					address = location.address;
					mapDetails = { ...location.position };
					locationDetails = { title, address, phone };
					break;
				case 'Patient':
					const patient = <Patient>resource;
					patientList = patient.name;
					break;
				case 'HealthcareService':
					const healthcareService = <HealthcareService>resource;
					serviceName = healthcareService.name;
					break;
			}
		}
	} catch (error) {
		// todo: add appointment id for failure when component is ready
		handleError(`Error for extractAppointmentDetailsFromBundle: ${error}`);
	}
	return [
		{
			id: '',
			serviceName,
			mapDetails,
			locationDetails,
			patientList,
			date,
			status,
			appointmentMethod,
			countOfOtherPatient,
		},
	];
};
