import { useSessionContext } from '@/shared/clientSession';
import { DefaultLocale } from '@/shared/config';
import { useEffect } from 'react';
import { useProfileContext, useSnowplow } from '../context';
import { MetricsSlug } from '../types';
import { getSiteType } from './analyticUtils';

export type GTMFields = {
	linkName?: string;
	pageLanguage?: string;
	pageName?: string;
	pageSection?: string;
	provinceSelection?: string;
	siteType?: ReturnType<typeof getSiteType>;
	event?: string;
	locale?: string;
	registrationId?: string;
	utmCampaign?: string | string[];
	utmMedium?: string | string[];
	utm_content?: string | string[];
	utm_source?: string | string[];
	banner?: string | string[];
	timestamp?: string;
};

/**
 * hook for GTM analytics
 * @param data type GTMFields
 * updates analytics fields
 */
export const usePageAnalytics = (data: GTMFields) => {
	const { status } = useSessionContext();
	const { pageView } = useSnowplow();
	const { accountStatus } = useProfileContext();

	const { pageName, pageSection, locale } = data;

	// for snowplow's page view
	useEffect(() => {
		function registerSnowplowPageview() {
			const pageUrl = window.location.href;

			if (pageUrl.includes(MetricsSlug)) {
				// skipping for `/metrics` slug,
				// as we need to send custom values for `page_name` and other keys based on user's encounter result.
				return;
			}

			if (!pageName) {
				console.warn(`[Snowplow]: Skipping page view call as no pageName was returned from contentful for ${pageUrl}.`);
				// if no pageName is returned, do not make any page view call
				return;
			}

			return pageView({
				page_name: pageName,
				page_section: pageSection || null,
				page_language: locale || DefaultLocale,
				page_url: pageUrl,
				site_type: getSiteType() || 'mobile',
			});
		}

		if (status === 'authenticated' && accountStatus === 'fetched') {
			// if user is authenticated and account data is available
			// then only trigger page view metric for registered user
			registerSnowplowPageview();
		}

		if (status === 'unauthenticated') {
			// if user is authenticated i.e. Guest user
			// then only trigger page view metric for guest user
			registerSnowplowPageview();
		}
	}, [status, accountStatus, pageName, pageSection, locale, pageView]);

	useEffect(() => {
		updateGTMDataLayer(data);
		window?.document?.addEventListener('click', handleInnerClick);
		return () => {
			window?.document?.removeEventListener('click', handleInnerClick);
		};
	}, [data]);
};

/**
 * capturing events at parent level
 * @param event JS event
 */
const handleInnerClick = (event: Event) => {
	const target = event?.target as HTMLAnchorElement;
	const linkName = target?.dataset?.analytics ?? target?.textContent;
	linkName && updateGTMDataLayer({ event: 'customLink', linkName });
};

/**
 * helper to update analytics data fields
 */
export const updateGTMDataLayer = (gtmData: GTMFields) => {
	window?.dataLayer?.push({
		...gtmData,
		siteType: getSiteType(),
	});
};
