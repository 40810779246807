import { Image, Text } from '@chakra-ui/react';
import type { CTAVariant } from '@ldfeplatform/drx-component-library.shared-types.types';
import { Modal } from '@ldfeplatform/drx-component-library.ui.molecules.modal';
import { useUnit } from 'effector-react';
import { useEffect, type FunctionComponent } from 'react';
import { updateGTMDataLayer } from '../../hooks';
import { replaceStringTemplates } from '../../template';
import { customActionHandler } from '../customActions';
import { $currentModal, $isVisible, onModalHidden, showModal } from './model';

export const ModalOutlet: FunctionComponent = () => {
	const { $isVisible: isVisible, $currentModal: currentModal } = useUnit({ $isVisible, $currentModal });

	const modalContent = currentModal?.data?.modalCollection?.items[0]?.featureCard;

	useEffect(() => {
		window.showModal = showModal;
	}, []);

	if (!isVisible) {
		return null;
	}

	if (!modalContent) {
		return null;
	}

	const { heading, body, image, ctaCollection } = modalContent;

	if (!heading) {
		return null;
	}

	const modalCTAs = ctaCollection?.items?.map((cta) => {
		const onClick = () => {
			if (cta?.dataAnalytics) updateGTMDataLayer({ event: 'customLink', linkName: cta?.dataAnalytics });
			customActionHandler({
				identifier: cta?.customAction,
				params: cta?.customActionParameters,
				redirectLink: cta?.linkUrl,
			});
		};

		return {
			label: cta?.label ?? '',
			variant: (cta?.variant ?? 'tertiary') as CTAVariant,
			onClick,
		};
	});

	if (!isVisible || !currentModal) return null;
	const templateProps = currentModal.templateProps;
	const templateVariables = currentModal.templateVariables;
	const isMarkdownModal = currentModal.templateProps.isMarkdownModal;
	const storeData = templateVariables.storeData;
	const modelText = storeData && Object.keys(storeData).length === 0 ? body : replaceStringTemplates(body, storeData);

	return (
		<Modal
			isOpen={isVisible}
			title={replaceStringTemplates(heading, templateVariables)}
			onClose={onModalHidden}
			autoFocus={false}
			footerCTAs={modalCTAs}
			isCentered
			ctaOrientation={templateProps?.ctaOrientation}
			{...(isMarkdownModal ? { markdown: modelText } : null)}
		>
			{!isMarkdownModal && (
				<Text textAlign="center" whiteSpace="pre-line">
					{modelText}
				</Text>
			)}
			{/* Ideally the width and height would come from contentful to help with CLS performance metrics */}
			{image && <Image mx="auto" htmlWidth={338} htmlHeight={217} src={image?.asset?.url} alt={image?.altText ?? ''} />}
		</Modal>
	);
};
